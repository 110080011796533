<template>
  <DrupalLink v-if="to" :to="to" class="block group">
    <div class="text-center">
      <div class="relative aspect-w-1 aspect-h-1">
        <div>
          <MediaImage
            v-if="teaserImage"
            v-bind="teaserImage"
            class="max-h-full max-w-full w-auto mx-auto relative top-1/2 -translate-y-1/2"
            :image-style="imageStyle"
            img-class="aspect-[1/1] object-contain"
          />
          <div
            v-if="statusMapped.length"
            class="absolute inset-y-0 left-0 flex flex-col justify-center"
          >
            <div class="flex flex-col items-start space-y-5">
              <div
                v-for="item in statusMapped"
                :key="item"
                class="badge uppercase transition-opacity group-hover:opacity-60"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="inline-block mx-auto mt-20 pt-20 px-20 border-t border-black lg:border-t-3 text-black w-1/2 transition-opacity group-hover:opacity-60"
      />
      <h3 class="title is-3 transition-opacity group-hover:opacity-60">
        {{ title }}
      </h3>
    </div>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type {
  MediaImageFragment,
  ProductTeaserFragment,
} from '#graphql-operations'
import { falsy } from '~/helpers/type'

const props = defineProps<{
  title?: string
  variation?: ProductTeaserFragment['variation']
  status?: string[]
  image?: MediaImageFragment
  url?: string | { path?: string }
}>()

const statusMapped = computed(
  () =>
    props.status ||
    props.variation?.statusTerms?.map((term) => term?.name).filter(falsy) ||
    [],
)

const to = computed<string | undefined>(() => {
  if (typeof props.url === 'string') {
    return props.url
  } else if (typeof props.url === 'object' && props.url && props.url.path) {
    return props.url.path
  }
  return undefined
})

const teaserImage = computed<MediaImageFragment | undefined>(
  () => props.image || props.variation?.images?.[0],
)

const imageStyle = defineExpedImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  cropType: 'contain',
  sizes: {
    xs: 375,
    sm: 768,
    lg: 375,
    xl: 450,
    '2xl': 756,
  },
})
</script>
